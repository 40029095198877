import React, { useState, useEffect } from "react"
import { Typography, Button, ButtonGroup, Paper, Grid, Tooltip } from "@mui/material"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import ListAltIcon from "@mui/icons-material/ListAlt"
import { getBooksByClass } from "../../fetch/trading"
import BookDialog from "./BookDialog"
import CheckBoxTwoToneIcon from "@mui/icons-material/CheckBoxTwoTone"
import CheckBoxOutlineBlankTwoToneIcon from "@mui/icons-material/CheckBoxOutlineBlankTwoTone"
import ChatTwoToneIcon from "@mui/icons-material/ChatTwoTone"
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone"

const transacted = ["Geografiebuch 8", "Französischbuch 7"]
const pending = ["Geschichtsbuch 7", "Geografie-Atlas 8", "Mathematikbuch 7"]
const waiting = ["Deutschbuch 8", "Englischbuch 8", "Deutschbuch 7", "Englischbuch 7"]

const CustomPaper = (props) => <Paper elevation={0} {...props} />

const Search = () => {
  const [currentClass, setCurrentClass] = useState(7)
  const [books, setBooks] = useState([])
  const [validBooks, setValidBooks] = useState([])
  const [currentBundle, setCurrentBundle] = useState({})
  const [dialogOpen, setDialogOpen] = useState(false)
  const [currentBook, setCurrentBook] = useState(null)

  const handleClick = (book) => {
    setCurrentBook(book)
    setDialogOpen(true)
  }

  const handleClose = () => {
    setCurrentBook(null)
    setDialogOpen(false)
  }

  useEffect(() => {
    getBooksByClass(currentClass, setBooks)
  }, [currentClass])

  useEffect(() => {
    const filtered = books.filter((b) => !b.Is_Bundle)
    const bundle = books.filter((b) => b.Is_Bundle)
    setValidBooks(filtered)
    setCurrentBundle(bundle[0])
  }, [books])

  const rows = Object.keys(validBooks).map((key) => validBooks[key])

  return (
    <>
      <Grid container justifyContent="space-between" sx={{ ml: "1vw", mt: "1vw", width: "98%" }}>
        <Grid item>
          <Typography variant="h5" align="left" sx={{ color: "secondary.main" }}>
            {currentClass ? `Bücherliste Klasse ${currentClass}` : "Extrabücher"} (Schüler)
          </Typography>
        </Grid>
        <Grid item>
          <Button
            sx={{ visibility: currentClass > 0 ? "visible" : "hidden" }}
            variant="outlined"
            color="secondary"
            startIcon={<ListAltIcon color="secondary" />}
            onClick={() => {
              setCurrentBook(currentBundle)
              setDialogOpen(true)
            }}>
            Alle Bücher zusammen verkaufen
          </Button>
        </Grid>
      </Grid>

      <Paper elevation={6} sx={{ width: "calc(100% - 2vw)", mx: "1vw", mt: "5vh", borderRadius: "20px" }}>
        <ButtonGroup
          variant="outlined"
          sx={{ width: "100%", height: "5vh", borderTopLeftRadius: "20px", borderTopRightRadius: "20px", overflow: "hidden" }}>
          {[7, 8, 9, 10, 11, 12, 13, 0].map((c) => (
            <Button
              onClick={() => setCurrentClass(c)}
              fullWidth
              key={c}
              sx={{
                backgroundColor: c !== currentClass ? "primary.main" : "white",
                color: c === currentClass ? "secondary.main" : "secondary.main",
                "&:last-child": { borderTopRightRadius: "20px" },
                "&:first-child": { borderTopLeftRadius: "20px" },
              }}>
              {c ? c : "Extra"}
            </Button>
          ))}
        </ButtonGroup>

        <TableContainer
          sx={{ borderBottomRightRadius: "20px", borderBottomLeftRadius: "20px", borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
          component={CustomPaper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "5%" }}>
                  <Tooltip arrow title="Buch gekauft oder verkauft" placement="top">
                    <CheckBoxOutlineBlankTwoToneIcon />
                  </Tooltip>
                </TableCell>
                <TableCell sx={{ width: "25%" }}>Titel</TableCell>
                <TableCell sx={{ width: "5%" }} align="right">
                  Fach
                </TableCell>
                <TableCell sx={{ width: "15%" }} align="right">
                  ISBN
                </TableCell>
                <TableCell sx={{ width: "15%" }} align="right">
                  Verlag
                </TableCell>
                <TableCell sx={{ width: "10%" }} align="right">
                  Originalpreis
                </TableCell>
                <TableCell sx={{ width: "25%" }} align="right">
                  Information
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow onClick={() => handleClick(row)} key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: "pointer" }}>
                  <TableCell component="th" scope="row">
                    {transacted.includes(row.Titel) ? (
                      <Tooltip arrow placement="top" title="Abgeschlossen">
                        <CheckBoxTwoToneIcon />
                      </Tooltip>
                    ) : pending.includes(row.Titel) ? (
                      <Tooltip arrow placement="top" title="Partner gefunden">
                        <ChatTwoToneIcon sx={{ color: "primary.main" }} />
                      </Tooltip>
                    ) : waiting.includes(row.Titel) ? (
                      <Tooltip arrow placement="top" title="Partner wird gesucht...">
                        <SearchTwoToneIcon />
                      </Tooltip>
                    ) : (
                      <Tooltip arrow placement="top" title="Nicht eingestellt">
                        <CheckBoxOutlineBlankTwoToneIcon />
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.Titel}
                  </TableCell>
                  <TableCell align="right">{row.Fach}</TableCell>
                  <TableCell align="right">{row.ISBN}</TableCell>
                  <TableCell align="right">{row.Verlag}</TableCell>
                  <TableCell align="right">{row.Originalpreis ? `${row.Originalpreis} €` : ""}</TableCell>
                  <TableCell align="right">{row.Kommentar}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {currentBook && <BookDialog handleClose={handleClose} open={dialogOpen} book={currentBook} />}
    </>
  )
}

export default Search
