/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { Typography, Grid, Stack, Box, Divider, Input, IconButton, InputAdornment, Button, Tooltip } from "@mui/material"
import SendIcon from "@mui/icons-material/Send"
import BookTwoToneIcon from "@mui/icons-material/BookTwoTone"
import { getAllChats } from "../../fetch/chat"
import ChatDialog from "./ChatDialog"
import LogoKlein from "../../components/logo/LogoKlein.png"
import CheckBoxTwoToneIcon from "@mui/icons-material/CheckBoxTwoTone"
import CheckBoxOutlineBlankTwoToneIcon from "@mui/icons-material/CheckBoxOutlineBlankTwoTone"
import ChatTwoToneIcon from "@mui/icons-material/ChatTwoTone"
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone"

const transacted = ["Geografiebuch 8", "Französischbuch 7"]
const pending = ["Geschichtsbuch 7", "Geografie-Atlas 8", "Mathematikbuch 7"]
const waiting = ["Deutschbuch 8", "Englischbuch 8", "Deutschbuch 7", "Englischbuch 7"]

const Chat = () => {
  const [allChats, setAllChats] = useState([])
  const [currentChat, setCurrentChat] = useState(null)
  const [currentChatHistory, setCurrentChatHistory] = useState(null)
  const [showCancelDialog, setShowCancelDialog] = useState(false)
  const [showFertigDialog, setShowFertigDialog] = useState(false)
  const [msg, setMsg] = useState("")

  const cancelDialog = () => {
    setShowFertigDialog(false)
    setShowCancelDialog(false)
  }

  useEffect(() => {
    getAllChats(setAllChats)
  }, [])

  useEffect(() => {
    getAllChats(setAllChats)
    if (currentChat) {
      const currentRole = allChats.filter((c) => c.CID === currentChat.CID)
      const { Verlauf } = currentChat
      const parsed = JSON.parse(Verlauf)
      const withRole = { ...parsed, role: currentRole[0].Aktion }
      setCurrentChatHistory(withRole)
    }
  }, [currentChat])

  let scroll = document.getElementById("chat")
  if (scroll) {
    scroll.scrollTop = scroll.scrollHeight
  }

  const nowRaw = new Date()
  const now = nowRaw.getDate() + "." + (nowRaw.getMonth() + 1) + "." + nowRaw.getFullYear()

  return (
    <>
      <Grid
        container
        justifyContent="space-evenly"
        sx={{ width: "100%", height: "100%", mb: "1vw", mr: "1vw", border: "2px solid", borderColor: "primary.main", borderRadius: "38px" }}>
        <Grid item xs={2.9}>
          <Box
            sx={{
              cursor: "default",
              height: "8vh",
              px: "5%",
              borderTopLeftRadius: "38px",
              overflow: "hidden",
            }}>
            <Typography variant="h5" sx={{ lineHeight: "8vh" }}>
              Chats
            </Typography>
          </Box>
          <Stack sx={{ overflow: "auto" }}>
            <Divider flexItem />
            {allChats.length > 0 &&
              allChats.map((c, index) => (
                <Box
                  key={index}
                  sx={{
                    cursor: "default",
                    height: "6vh",
                    lineHeight: "6vh",
                    px: "5%",
                    my: "1vh",
                    borderTopRightRadius: "38px",
                    borderBottomRightRadius: "38px",
                    color: c.Status === "matched" ? "inherit" : "grey",
                    // textDecoration: c?.CID === currentChat?.CID ? "underline" : "inherit",
                    backgroundColor: c?.CID === currentChat?.CID ? "#ebebeb" : "inherit",
                    overflow: "hidden",
                  }}
                  onClick={() => setCurrentChat(c)}>
                  {transacted.includes(c.Titel) ? (
                    <Tooltip arrow placement="top" title="Abgeschlossen">
                      <CheckBoxTwoToneIcon sx={{ color: c.Status === "matched" ? "primary.main" : "inherit", verticalAlign: "middle", mr: 1 }} />
                    </Tooltip>
                  ) : pending.includes(c.Titel) ? (
                    <Tooltip arrow placement="top" title="Partner gefunden">
                      <ChatTwoToneIcon sx={{ color: c.Status === "matched" ? "primary.main" : "inherit", verticalAlign: "middle", mr: 1 }} />
                    </Tooltip>
                  ) : waiting.includes(c.Titel) ? (
                    <Tooltip arrow placement="top" title="Partner wird gesucht...">
                      <SearchTwoToneIcon sx={{ color: c.Status === "matched" ? "primary.main" : "inherit", verticalAlign: "middle", mr: 1 }} />
                    </Tooltip>
                  ) : (
                    <Tooltip arrow placement="top" title="Nicht eingestellt">
                      <CheckBoxOutlineBlankTwoToneIcon
                        sx={{ color: c.Status === "matched" ? "primary.main" : "inherit", verticalAlign: "middle", mr: 1 }}
                      />
                    </Tooltip>
                  )}
                  {c.Is_Bundle ? "Bücherpaket" : c.Titel}
                </Box>
              ))}
          </Stack>
        </Grid>
        <Grid item xs={0.2}>
          <Divider orientation="vertical" sx={{ borderColor: "primary.main" }} />
        </Grid>
        <Grid item xs={8.9}>
          <Box sx={{ p: "2vh" }}>
            {!currentChat && (
              <Box sx={{ mx: "auto", mt: "34vh", color: "primary.main" }}>
                <Typography variant="h5" align="center" sx={{ fontWeight: "bold" }}>
                  <img src={LogoKlein} height="50px" alt="Bookay" />
                  Chat
                </Typography>
              </Box>
            )}
            {currentChat && (
              <>
                <Grid container justifyContent="space-between" sx={{ height: "8vh", display: "inline-flex" }}>
                  <Grid item xs={0.5}>
                    <BookTwoToneIcon fontSize="large" sx={{ color: currentChat.Status === "matched" ? "primary.main" : "inherit " }} />
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="h5" sx={{ cursor: "default", color: currentChat.Status === "matched" ? "inherit" : "grey" }}>
                      {currentChat.Titel} • {currentChat.Klasse} • {currentChat.Name} {currentChat.Status === "matched" ? "" : "• Fertig"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4.5}>
                    {currentChat.Status === "matched" && (
                      <Box sx={{ float: "right" }}>
                        <Button
                          onClick={() => setShowFertigDialog(true)}
                          variant="contained"
                          sx={{ backgroundColor: "#3de637", width: "10vw", mr: "2vw", "&:hover": { backgroundColor: "#b3ffb0" } }}
                          disableElevation>
                          ✅ Fertig
                        </Button>
                        <Button
                          onClick={() => setShowCancelDialog(true)}
                          variant="outlined"
                          sx={{ width: "10vw", color: "red", borderColor: "red", "&:hover": { borderColor: "red", backgroundColor: "salmon" } }}>
                          ❌ Abbrechen
                        </Button>
                      </Box>
                    )}
                    {currentChat.Status === "fertig" && currentChat.VerhandelterPreis !== 0 && (
                      <Typography variant="h5" align="right" sx={{ color: "grey" }}>
                        {currentChat?.VerhandelterPreis ? currentChat?.VerhandelterPreis + " €" : ""}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Divider sx={{ mt: "-2vh" }} />
                <Stack id="chat" sx={{ overflow: "auto", height: currentChat.Status === "fertig" ? "81vh" : "75vh", mt: "1vh", pr: "1vw" }}>
                  {currentChatHistory &&
                    currentChatHistory.chat.map((c, index) => (
                      <Typography
                        key={index}
                        align={currentChatHistory.role.toString() === c.sender.toString() ? "right" : "left"}
                        sx={{
                          border: "1px solid gray",
                          borderRadius: "20px",
                          borderTopLeftRadius: currentChatHistory.role.toString() === c.sender.toString() ? "20px" : "0",
                          borderTopRightRadius: currentChatHistory.role.toString() === c.sender.toString() ? "0" : "20px",
                          height: "auto",
                          width: "auto",
                          maxWidth: "60%",
                          p: "1vh",
                          my: "1vh",
                          ml: currentChatHistory.role.toString() === c.sender.toString() ? "auto" : "0",
                          mr: currentChatHistory.role.toString() === c.sender.toString() ? "0" : "auto",
                          backgroundColor: currentChatHistory.role.toString() === c.sender.toString() ? "primary.main" : "#74a877",
                        }}>
                        {c.msg}
                      </Typography>
                    ))}
                  {currentChat && currentChat?.Status === "fertig" && (
                    <Typography
                      align="center"
                      sx={{
                        border: "1px solid gray",
                        borderRadius: "20px",
                        height: "auto",
                        mt: "5vh",
                        backgroundColor: "grey.100",
                      }}>
                      <em>Abgeschlossen am {now}</em>
                      {currentChat?.VerhandelterPreis && <em> für {currentChat?.VerhandelterPreis} €</em>}
                    </Typography>
                  )}
                </Stack>
                <Input
                  autoFocus
                  sx={{
                    border: "2px solid",
                    borderRadius: "20px",
                    borderColor: currentChat.Status === "fertig" || msg.length <= 0 || msg.trim() === "" ? "grey" : "primary.main",
                    height: "5vh",
                    mt: "1vh",
                    p: "1vh",
                    visibility: currentChat.Status === "fertig" ? "hidden" : "visible",
                  }}
                  fullWidth
                  id="msg"
                  name="msg"
                  onChange={(e) => setMsg(e.target.value)}
                  value={msg}
                  variant="standard"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton>
                        <SendIcon sx={{ color: currentChat.Status === "fertig" || msg.length <= 0 || msg.trim() === "" ? "grey" : "primary.main" }} />
                      </IconButton>
                    </InputAdornment>
                  }
                  disableUnderline
                />
              </>
            )}
          </Box>
        </Grid>
      </Grid>

      {(showFertigDialog || showCancelDialog) && (
        <ChatDialog
          showFertigDialog={showFertigDialog}
          showCancelDialog={showCancelDialog}
          LID={currentChat.LID}
          Aktion={currentChat.Aktion}
          cancelDialog={cancelDialog}
        />
      )}
    </>
  )
}

export default Chat
