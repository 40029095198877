import React from "react"
import { Container, Link, Button, Typography, Box, Grid } from "@mui/material"
import { F_TRADING, F_VERWALTUNG } from "../../constants/routes"

const Presentpage = () => {
  return (
    <Container component="main" maxWidth="100%">
      <Typography variant="h4" align="center" sx={{ fontWeight: "bold", my: "4vh" }}>
        Bookay Demoversion
      </Typography>
      <Grid container>
        <Grid item xs={12} lg={0} display={{ lg: "none", xs: "inherit" }}>
          <Typography align="center" sx={{ fontWeight: "bold", width: "100%" }} gutterBottom>
            Die Demoversion ist nur geeignet für Bildschirmbreiten über 1200 px
          </Typography>
        </Grid>
        <Grid item container lg={12} spacing={3} display={{ xs: "none", lg: "inherit" }}>
          <Grid item lg={12}>
            <Typography align="center" variant="h5" gutterBottom>
              Übersicht
            </Typography>
            <Typography align="center">In die Demoversion sind feste Daten eingetragen, die nicht bearbeitet werden können.</Typography>
          </Grid>
          <Grid item lg={6}>
            <Box
              sx={{
                float: "right",
                py: 2,
                px: 4,
                width: "30vw",
                borderWidth: "2px",
                borderStyle: "solid",
                borderColor: "primary.main",
                borderRadius: "20px",
                mt: 2,
              }}>
              <Link href={F_TRADING}>
                <Button
                  variant="outlined"
                  fullWidth
                  sx={{
                    my: 1,
                    backgroundColor: "primary.main",
                    borderColor: "secondary.main",
                    color: "white",
                    p: 2,
                    "&:hover": { backgroundColor: "secondary.main" },
                  }}>
                  Schüler-Ansicht
                </Button>
              </Link>
              <Typography paragraph>Diese Ansicht der App sehen die Schüler</Typography>
              <Typography paragraph>Hier werden Bücher gekauft und verkauft</Typography>
            </Box>
          </Grid>
          <Grid item lg={6}>
            <Box
              sx={{
                py: 2,
                px: 4,
                width: "30vw",
                borderWidth: "2px",
                borderStyle: "solid",
                borderColor: "bmprimary.main",
                borderRadius: "20px",
                mt: 2,
              }}>
              <Link href={F_VERWALTUNG}>
                <Button
                  variant="outlined"
                  fullWidth
                  sx={{
                    my: 1,
                    backgroundColor: "bmprimary.main",
                    borderColor: "bmsecondary.main",
                    color: "white",
                    p: 2,
                    "&:hover": { backgroundColor: "bmsecondary.main" },
                  }}>
                  Schulverwaltung-Ansicht
                </Button>
              </Link>
              <Typography paragraph>Diese Ansicht der App sieht die Schulverwaltung</Typography>
              <Typography paragraph>Hier werden die Bücherlisten erstellt</Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Typography mt={10} align="center">
        <Link target="_blank" rel="noopener noreferrer" href="https://www.bookay-buecher.de/" color="text.secondary">
          Zurück
        </Link>
      </Typography>
      <Box component="footer" sx={{ bgcolor: "background.paper", py: 6 }}>
        <Container maxWidth="lg">
          <Typography variant="h6" align="center" gutterBottom>
            Bookay 2023
          </Typography>
          <Typography variant="subtitle1" align="center" component="p">
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.bookay-buecher.de/start?page=impressum"
              sx={{ textDecoration: "none" }}
              color="text.secondary">
              Impressum |{" "}
            </Link>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.bookay-buecher.de/start?page=datenschutz"
              sx={{ textDecoration: "none" }}
              color="text.secondary">
              Datenschutzerklärung |{" "}
            </Link>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.bookay-buecher.de/start?page=agb"
              sx={{ textDecoration: "none" }}
              color="text.secondary">
              AGB
            </Link>
          </Typography>
          <Typography variant="body2" color="text.secondary" align="center">
            {"Copyright © "}
            <Link color="inherit" target="_blank" rel="noopener noreferrer" href="https://www.bookay-buecher.de/start?page=impressum">
              Dmitrij Tjumenzew
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Container>
      </Box>
    </Container>
  )
}

export default Presentpage
