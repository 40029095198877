import React from "react"
import { Box, AppBar } from "@mui/material"
import LogoKleinWeiss from "./logo/LogoKleinWeiss.png"

import UserMenu from "./UserMenu"

const Header = ({ content, showUserMenu, settings, bookmanagement }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        width: "12vw",
        maxWidth: "20vw",
        zIndex: 100,
      }}>
      <Box flexGrow={0}>
        <AppBar elevation={0} position="sticky" sx={{ backgroundColor: bookmanagement ? "bmprimary.main" : "initial" }}>
          <Box sx={{ display: "grid", gridTemplateRows: "16vh auto 10vh", gridTemplateColumns: "1fr", rowGap: "auto", height: "100vh" }}>
            <Box sx={{ height: "16vh", textAlign: "center" }}>
              <UserMenu showName={showUserMenu} settings={settings} bookmanagement={bookmanagement} />
            </Box>
            <Box sx={{ height: "74vh", textAlign: "center", display: "grid" }}>
              <Box sx={{ my: "auto" }}>{content || <></>}</Box>
            </Box>
            <Box sx={{ height: "10vh", textAlign: "center" }}>
              <img style={{ height: "auto", width: "10vw" }} src={LogoKleinWeiss} alt="logo" />
            </Box>
          </Box>
        </AppBar>
      </Box>
    </Box>
  )
}

export default Header
