import React from "react"
import { Card, Grid, Typography, Tooltip } from "@mui/material"
import CheckBoxTwoToneIcon from "@mui/icons-material/CheckBoxTwoTone"
import CheckBoxOutlineBlankTwoToneIcon from "@mui/icons-material/CheckBoxOutlineBlankTwoTone"
import ChatTwoToneIcon from "@mui/icons-material/ChatTwoTone"
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone"

const transacted = ["Geografiebuch 8", "Französischbuch 7"]
const pending = ["Geschichtsbuch 7", "Geografie-Atlas 8", "Mathematikbuch 7"]
const waiting = ["Deutschbuch 8", "Englischbuch 8", "Deutschbuch 7", "Englischbuch 7"]

const ActivePositionItem = ({ details, setCurrentAP, goToChat }) => {
  const { Titel, Klasse, Name, Is_Bundle, Status } = details
  return (
    <Card
      variant="outlined"
      onClick={() => setCurrentAP(details)}
      sx={{
        width: "90%",
        borderColor: Status === "matched" ? "primary.main" : "",
        mx: "5%",
        my: "1vh",
        height: "5vh",
        px: "1vw",
        cursor: "pointer",
        "&:hover": { backgroundColor: Status === "matched" ? "rgba(152, 227, 156, 0.4)" : "lightgray" },
      }}>
      <Grid container alignItems="flex-end">
        <Grid item xs={1}>
          {transacted.includes(Titel) ? (
            <Tooltip arrow placement="top" title="Abgeschlossen">
              <CheckBoxTwoToneIcon sx={{ verticalAlign: "bottom", mt: "10%", color: Status === "matched" ? "primary.main" : "inherit" }}/>
            </Tooltip>
          ) : pending.includes(Titel) ? (
            <Tooltip arrow placement="top" title="Partner gefunden">
              <ChatTwoToneIcon sx={{ verticalAlign: "bottom", mt: "10%", color: Status === "matched" ? "primary.main" : "inherit" }} />
            </Tooltip>
          ) : waiting.includes(Titel) ? (
            <Tooltip arrow placement="top" title="Partner wird gesucht...">
              <SearchTwoToneIcon sx={{ verticalAlign: "bottom", mt: "10%", color: Status === "matched" ? "primary.main" : "inherit" }}/>
            </Tooltip>
          ) : (
            <Tooltip arrow placement="top" title="Nicht eingestellt">
              <CheckBoxOutlineBlankTwoToneIcon sx={{ verticalAlign: "bottom", mt: "10%", color: Status === "matched" ? "primary.main" : "inherit" }}/>
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={Status === "matched" ? (Is_Bundle ? 8.5 : 4) : Is_Bundle ? 11 : 6.5}>
          <Typography align="left">{Is_Bundle ? `Bücherpaket Klasse ${Klasse}` : Titel}</Typography>
        </Grid>
        {Is_Bundle ? (
          <></>
        ) : (
          <>
            <Grid item xs={1}>
              <Typography align="right">{Klasse}. Kl.</Typography>
            </Grid>
            <Grid item xs={3.5}>
              <Typography align="right">{Name}</Typography>
            </Grid>
          </>
        )}
        {Status === "matched" ? (
          <Grid item xs={2.5} onClick={() => goToChat()}>
            <Typography align="right" sx={{ color: "primary.main", textDecoration: "underline" }}>
              Zum Chat
            </Typography>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Card>
  )
}

export default ActivePositionItem
