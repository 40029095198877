import React from "react"
import { Route, Routes as SwitchRoutes, useLocation } from "react-router-dom"
import Schuladmin from "./pages/Bookmanagement/Schuladmin"
import Trading from "./pages/Trading/Trading"
import Presentpage from "./pages/Presentpage/Presentpage"
import { F_VERWALTUNG, F_TRADING, F_INDEX } from "./constants/routes"

const App = () => {
  const location = useLocation()

  return (
    <SwitchRoutes location={location} role="main">
      <Route path={F_INDEX} element={<Presentpage />} />
      <Route path={F_TRADING} element={<Trading />} />
      <Route path={F_VERWALTUNG} element={<Schuladmin />} />
      <Route path="*" element={<Presentpage />} />
    </SwitchRoutes>
  )
}

export default App
