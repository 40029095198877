import React, { useState } from "react"
import { Box, Button, Grid } from "@mui/material"
import Header from "../../components/Header"
import ActivePositions from "./ActivePositions"
import Chat from "./Chat"
import Search from "./Search"
import History from "./History"

const TabList = [
  // component can be deleted and actually can be zusammengefasst with Tabs
  {
    name: "Bücherlisten",
    component: <Search />,
  },
  {
    name: "Meine Bücher",
    component: <ActivePositions />,
  },
  {
    name: "Chat",
    component: <Chat />,
  },
  {
    name: "Verlauf",
    component: <History />,
  },
]

const Trading = () => {
  const [currentTab, setCurrentTab] = useState("Bücherlisten")

  const Tabs = {
    Bücherlisten: <Search />,
    "Meine Bücher": <ActivePositions goToChat={() => setCurrentTab("Chat")} />,
    Verlauf: <History goToChat={() => setCurrentTab("Chat")} />,
    Chat: <Chat goToVerlauf={() => setCurrentTab("Verlauf")} />,
  }

  const headerContent = (
    <Grid container spacing="4vh">
      {TabList.map((t) => (
        <Grid container item xs={12} columns={10} key={t.name}>
          <Grid item xs={1}>
            <Box
              sx={{
                height: "8vh",
                width: "100%",
                visibility: "hidden",
              }}></Box>
          </Grid>

          <Grid item xs={8}>
            <Button
              variant="contained"
              onClick={() => setCurrentTab(t.name)}
              sx={{
                height: "8vh",
                width: "100%",
                // mx: "10%",
                textTransform: "none",
                boxShadow: "none",
                backgroundColor: currentTab === t.name ? "white" : "secondary.main",
                color: currentTab === t.name ? "secondary.main" : "white",
              }}>
              {t.name}
            </Button>
          </Grid>

          <Grid item xs={1}>
            <Box
              sx={{
                backgroundColor: "white",
                height: "8vh",
                width: "100%",
                visibility: currentTab === t.name ? "visible" : "hidden",
              }}></Box>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )

  return (
    <Box sx={{ height: "100vh", width: "100vw", backgroundColor: "primary.main" }}>
      <Header content={headerContent} showUserMenu />
      <Box
        sx={{
          position: "fixed",
          top: "1vw",
          left: "12vw",
          borderRadius: "38px",
          backgroundColor: "white",
          height: "calc(100vh - 2vw)",
          width: "87vw",
          p: "1vw",
        }}>
        {Tabs[currentTab] || Tabs.Search}
      </Box>
    </Box>
  )
}

export default Trading
