import React, { useState, useEffect } from "react"
import { Typography, Grid, Divider } from "@mui/material"
import { getActivePositions } from "../../fetch/trading"
import ActivePositionItem from "./ActivePositionItem"
import ActivePositionDialog from "./ActivePositionDialog"

const ActivePositions = ({ goToChat }) => {
  const [allAP, setAllAP] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [allSuchePosK, setAllSuchePosK] = useState([])
  const [allSuchePosV, setAllSuchePosV] = useState([])
  const [allMatchedPosK, setAllMatchedPosK] = useState([])
  const [allMatchedPosV, setAllMatchedPosV] = useState([])
  const [currentAP, setCurrentAP] = useState(null)

  useEffect(() => {
    getActivePositions(setAllAP)
  }, [])

  useEffect(() => {
    const suche = allAP.filter((p) => p.Status === "suche")
    const sucheK = suche.filter((p) => p.Aktion)
    const sucheV = suche.filter((p) => !p.Aktion)
    setAllSuchePosK(sucheK)
    setAllSuchePosV(sucheV)
    const matched = allAP.filter((p) => p.Status === "matched")
    const matchedK = matched.filter((p) => p.Aktion)
    const matchedV = matched.filter((p) => !p.Aktion)
    setAllMatchedPosK(matchedK)
    setAllMatchedPosV(matchedV)
  }, [allAP])

  useEffect(() => {
    setModalOpen(!!currentAP)
  }, [currentAP])

  return (
    <>
      <Typography variant="h5" align="left" sx={{ color: "secondary.main", ml: "1vw", pt: "1vw" }}>
        Meine Bücher
      </Typography>

      <Grid container justifyContent="space-evenly" sx={{ height: "90%", mt: "5vh" }}>
        <Grid item xs={5.9}>
          <Typography variant="subtitle1" align="center">
            Ich kaufe:
          </Typography>
          <Typography variant="subtitle2" align="center" sx={{ mt: "1vh" }}>
            Partner wird gesucht
          </Typography>
          {allSuchePosK.length > 0 && allSuchePosK.map((p) => <ActivePositionItem setCurrentAP={setCurrentAP} key={p.LID} details={p} />)}
          <Divider sx={{ mt: "3vh", width: "90%", mx: "5%" }} />
          <Typography variant="subtitle2" align="center" sx={{ mt: "1vh" }}>
            Partner gefunden
          </Typography>
          {allMatchedPosK.length > 0 && allMatchedPosK.map((p) => <ActivePositionItem setCurrentAP={setCurrentAP} key={p.LID} details={p} />)}
        </Grid>
        <Grid item xs={0.2}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={5.9}>
          <Typography variant="subtitle1" align="center">
            Ich verkaufe:
          </Typography>

          <Typography variant="subtitle2" align="center" sx={{ mt: "1vh" }}>
            Partner wird gesucht
          </Typography>
          {allSuchePosV.length > 0 &&
            allSuchePosV.map((p) => <ActivePositionItem goToChat={goToChat} setCurrentAP={setCurrentAP} key={p.LID} details={p} />)}
          <Divider sx={{ mt: "3vh", width: "90%", mx: "5%" }} />
          <Typography variant="subtitle2" align="center" sx={{ mt: "1vh" }}>
            Partner gefunden
          </Typography>
          {allMatchedPosV.length > 0 &&
            allMatchedPosV.map((p) => <ActivePositionItem goToChat={goToChat} setCurrentAP={setCurrentAP} key={p.LID} details={p} />)}
        </Grid>
      </Grid>

      {currentAP && (
        <ActivePositionDialog
          goToChat={goToChat}
          open={modalOpen}
          details={currentAP}
          handleClose={() => {
            setModalOpen(false)
            setCurrentAP(null)
          }}
        />
      )}
    </>
  )
}

export default ActivePositions
