import React from "react"
import { Card, Grid, Typography, Tooltip } from "@mui/material"
import CheckBoxTwoToneIcon from "@mui/icons-material/CheckBoxTwoTone"

const HistoryItem = ({ details, setCurrentHP }) => {
  const { Titel, Klasse, Name, Is_Bundle, VerhandelterPreis } = details
  return (
    <Card
      variant="outlined"
      onClick={() => setCurrentHP(details)}
      sx={{
        width: "90%",
        mx: "5%",
        my: "1vh",
        height: "5vh",
        px: "1vw",
        cursor: "pointer",
        "&:hover": { backgroundColor: "lightgray" },
      }}>
      <Grid container alignItems="flex-end">
        <Grid item xs={1}>
          <Tooltip arrow placement="top" title="Abgeschlossen">
            <CheckBoxTwoToneIcon sx={{ verticalAlign: "bottom", mt: "10%" }} />
          </Tooltip>
        </Grid>
        <Grid item xs={Is_Bundle ? 10 : VerhandelterPreis ? 5.5 : 6.5}>
          <Typography align="left">{Is_Bundle ? `Bücherpaket Klasse ${Klasse}` : Titel}</Typography>
        </Grid>
        {Is_Bundle ? (
          <></>
        ) : (
          <>
            <Grid item xs={1}>
              <Typography align="right">{Klasse}. Kl.</Typography>
            </Grid>
            <Grid item xs={3.5}>
              <Typography align="right">{Name}</Typography>
            </Grid>
          </>
        )}
        {VerhandelterPreis ? (
          <Grid item xs={1}>
            <Typography align="right">{VerhandelterPreis} €</Typography>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Card>
  )
}

export default HistoryItem
