const faecher = {
  0: {
    FID: 1,
    Name: "Deutsch",
    Art: null,
    HatBild: 1,
    BildURL: null,
    HinzugefuegtVon: 0,
    Bestaetigt: 1,
  },
  1: {
    FID: 2,
    Name: "Englisch",
    Art: null,
    HatBild: 1,
    BildURL: null,
    HinzugefuegtVon: 0,
    Bestaetigt: 1,
  },
  2: {
    FID: 3,
    Name: "Mathematik",
    Art: null,
    HatBild: 1,
    BildURL: null,
    HinzugefuegtVon: 0,
    Bestaetigt: 1,
  },
  3: {
    FID: 4,
    Name: "Geografie",
    Art: null,
    HatBild: 1,
    BildURL: null,
    HinzugefuegtVon: 0,
    Bestaetigt: 1,
  },
  4: {
    FID: 5,
    Name: "Geschichte",
    Art: null,
    HatBild: 1,
    BildURL: null,
    HinzugefuegtVon: 0,
    Bestaetigt: 1,
  },
  5: {
    FID: 6,
    Name: "Franzoesisch",
    Art: null,
    HatBild: 1,
    BildURL: null,
    HinzugefuegtVon: 0,
    Bestaetigt: 1,
  },
  6: {
    FID: 7,
    Name: "Biologie",
    Art: null,
    HatBild: 1,
    BildURL: null,
    HinzugefuegtVon: 0,
    Bestaetigt: 1,
  },
  7: {
    FID: 8,
    Name: "Russisch",
    Art: null,
    HatBild: 1,
    BildURL: null,
    HinzugefuegtVon: 0,
    Bestaetigt: 1,
  },
  8: {
    FID: 9,
    Name: "Politik",
    Art: null,
    HatBild: 1,
    BildURL: null,
    HinzugefuegtVon: 0,
    Bestaetigt: 1,
  },
  9: {
    FID: 14,
    Name: "fach",
    Art: null,
    HatBild: 0,
    BildURL: null,
    HinzugefuegtVon: 1,
    Bestaetigt: 0,
  },
}

export const getFaecher = (setAll) => {
  const formatted = faecher
  let result = Object.keys(formatted).map((key) => formatted[key])
  setAll(result)
}
