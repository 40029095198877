import React from "react"
import { Box, Typography, Paper, Divider, Grid } from "@mui/material"

const licencesList = [
  {
    title: "Progressiv",
    id: "Progressiv",
    subheader: "Für kleinere Schulen",
    price: "1",
    description: ["∞ Schülerzugänge", "1 Jahr Laufzeit"],
    shortDescription: ["∞ Schülerzugänge", " | 1 Jahr Laufzeit", " | 1 € / Schüler"],
    buttonText: "Loslegen",
    buttonVariant: "outlined",
  },
  {
    title: "Basis",
    id: "Basis",
    subheader: "Für mittlere Schulen",
    price: "50",
    description: ["500 Schülerzugänge", "4 Wochen Laufzeit"],
    shortDescription: ["500 Schülerzugänge", " | 4 Wochen Laufzeit"],
    buttonText: "Loslegen",
    buttonVariant: "contained",
  },
  {
    title: "Komplett",
    id: "Full",
    subheader: "Für große Schulen",
    price: "100",
    description: ["∞ Schülerzugänge", " 1 Jahr Laufzeit"],
    shortDescription: ["∞ Schülerzugänge", " | 1 Jahr Laufzeit"],
    buttonText: "Loslegen",
    buttonVariant: "outlined",
  },
]

const modules = [
  {
    title: "Fast Track",
    id: "Fast-Track",
    subheader: "Wir erledigen alles",
    price: "50",
    description: [
      "Kein manuelles Eintragen mehr! Wir tragen alle Bücher selbst in Ihr Profil ein. Sie benötigen nur ein Excel- oder PDF-Dokument ihrer Listen.",
    ],
    shortDescription: ["Schneller vorankommen"],
    buttonText: "Hinzufügen",
    buttonVariant: "outlined",
  },
  {
    title: "Eigene Fächer",
    id: "Eigene-Faecher",
    subheader: "Schulspezifische Fächer",
    price: "10",
    description: ["Fügen Sie Ihre eigenen spezifischen Fächer hinzu. Hier sind alle Fächer aufgelistet, die in jeder Lizenz enthalten sind: ..."],
    alleFaecher: ["Deutsch, Englisch, Französisch, Mathematik, Geschichte, Geografie, Informatik, Sport"],
    shortDescription: ["Eigene Fächer"],
    buttonText: "Hinzufügen",
    buttonVariant: "contained",
  },
  {
    title: "Personalisiert",
    id: "Personalisiertes-Modul",
    subheader: "Nichts passendes gefunden?",
    price: "",
    description: [
      "Wir können schnell und einfach individuelle Extra-Funktionen für Ihre Schule konzipieren, bspw. bestimmte Klassenstufen hinzufügen etc.",
    ],
    shortDescription: ["Individuelle Lösungen"],
    buttonText: "Hinzufügen",
    buttonVariant: "contained",
  },
]

const Lizenzen = () => {
  const bezahlt = true
  const boughtAt = new Date()

  const formattedBoughtAt = boughtAt.getDate() + "." + (boughtAt.getMonth() + 1) + "." + boughtAt.getFullYear()
  const formattedValidThru = boughtAt.getDate() + "." + (boughtAt.getMonth() + 1) + "." + (boughtAt.getFullYear() + 1)

  const lizenzDaten = {
    beschreibung: "Basis-Lizenz",
    bezahlt: 1,
    datum: "2023-04-24 21:29:40",
    name: "Basis",
    preis: 50,
  }
  const modulDaten = [
    {
      beschreibung: "Eigene Fächer hinzufügen",
      id: 7,
      name: "Eigene Fächer hinzufügen",
      preis: 10,
    },
    {
      beschreibung: "Fast Track",
      id: 8,
      name: "Fast Track",
      preis: 50,
    },
  ]

  const suggestedLicence =
    lizenzDaten.name === "Komplett" ? licencesList.filter((l) => l.title === "Progressiv")[0] : licencesList.filter((l) => l.title === "Komplett")[0]

  let suggestedModules = []
  switch (modulDaten.length) {
    case 1:
      suggestedModules = modules.filter((m) => m.title !== modulDaten[0].name)
      break
    case 2:
      suggestedModules = modules.filter((m) => m.title === "Personalisiert")
      break
    default:
      suggestedModules = modules
  }

  return (
    <Box
      sx={{
        position: "fixed",
        top: "1vw",
        left: "12vw",
        borderRadius: "38px",
        backgroundColor: "white",
        height: "calc(100vh - 2vw)",
        width: "87vw",
        p: "1vw",
        overflow: "auto",
        msOverflowStyle: "-ms-autohiding-scrollbar" /* IE and Edge */, // ????
        scrollbarWidth: "none" /* Firefox */,
      }}>
      <Typography variant="h5" align="left" sx={{ color: "bmsecondary.main", ml: "1vw", pt: "1vw" }} gutterBottom>
        Lizenzen und Module
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={5.9}>
          <Typography variant="h5" sx={{ ml: "1vw", my: "2vh" }} gutterBottom>
            Angelegt
          </Typography>
          <Typography variant="h6" sx={{ ml: "1vw" }} gutterBottom>
            Lizenz
          </Typography>
          <Paper
            elevation={0}
            sx={{
              my: 2,
              p: 2,
              bgcolor: "bmprimary.main",
              width: "60%",
              ml: "1vw",
            }}>
            <Typography variant="h6">{lizenzDaten.name}</Typography>
            <Typography variant="subtitle1">{lizenzDaten.beschreibung}</Typography>
            <Typography variant="subtitle1">Angelegt am: {formattedBoughtAt}</Typography>
            <Typography variant="subtitle1">Gültig bis: {formattedValidThru}</Typography>
            <Typography variant="subtitle1">Bezahlt: {bezahlt ? "Ja" : "Nein"}</Typography>
          </Paper>
          <Typography variant="h6" sx={{ ml: "1vw" }} gutterBottom>
            Module
          </Typography>
          {modulDaten.length > 0 &&
            modulDaten.map((m) => (
              <Paper
                key={m.name}
                elevation={0}
                sx={{
                  my: 2,
                  p: 2,
                  bgcolor: "grey.200",
                  width: "60%",
                  ml: "1vw",
                }}>
                <Typography variant="h6">{m.name}</Typography>
                <Typography variant="subtitle1">{m.beschreibung}</Typography>
              </Paper>
            ))}
        </Grid>
        <Grid item xs={0.2}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={5.9}>
          <Typography variant="h5" sx={{ my: "2vh" }}>
            Vorschläge
          </Typography>
          <Typography variant="h6" sx={{ ml: "1vw" }} gutterBottom>
            Lizenz
          </Typography>
          <Paper
            elevation={0}
            sx={{
              my: 2,
              p: 2,
              bgcolor: "bmprimary.main",
              width: "60%",
              ml: "1vw",
            }}>
            <Typography variant="h6">{suggestedLicence.title}</Typography>
            <Typography variant="subtitle1">{suggestedLicence.description}</Typography>
          </Paper>
          <Typography variant="h6" sx={{ ml: "1vw" }} gutterBottom>
            Module
          </Typography>
          {suggestedModules.length > 0 &&
            suggestedModules.map((m) => (
              <Paper
                key={m.title}
                elevation={0}
                sx={{
                  my: 2,
                  p: 2,
                  bgcolor: "grey.200",
                  width: "60%",
                  ml: "1vw",
                }}>
                <Typography variant="h6">{m.title}</Typography>
                <Typography variant="subtitle1">{m.description}</Typography>
              </Paper>
            ))}
        </Grid>
      </Grid>
    </Box>
  )
}

export default Lizenzen
