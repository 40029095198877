const books = {
  7: {
    0: {
      id: 153,
      Titel: "Bundle 7",
      ISBN: 0,
      Fach: "Extra",
      Originalpreis: null,
      Kommentar: null,
      Verlag: null,
      Is_Bundle: 1,
      Klasse: 7,
    },
    1: {
      id: 1,
      Titel: "Deutschbuch 7",
      ISBN: 1234567890,
      Fach: "Deutsch",
      Originalpreis: 20,
      Kommentar: "Das Deutschbuch für die Klasse 7",
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 7,
    },
    2: {
      id: 59,
      Titel: "Englischbuch 7",
      ISBN: 1234567890,
      Fach: "Englisch",
      Originalpreis: 12,
      Kommentar: "Das Englischbuch für die Klasse 7",
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 7,
    },
    3: {
      id: 139,
      Titel: "Französischbuch 7",
      ISBN: 1234567890,
      Fach: "Französisch",
      Kommentar: "Das Französischbuch für die Klasse 7",
      Originalpreis: 25,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 7,
    },
    4: {
      id: 121,
      Titel: "Mathematikbuch 7",
      ISBN: 1234567890,
      Fach: "Mathematik",
      Kommentar: "Das Mathematikbuch für die Klasse 7",
      Originalpreis: 18,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 7,
    },
    5: {
      id: 154,
      Titel: "Geschichtsbuch 7",
      ISBN: 1234567890,
      Fach: "Geschichte",
      Kommentar: "Das Geschichtsbuch für die Klasse 7",
      Originalpreis: 30,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 7,
    },
    6: {
      id: 119,
      Titel: "Geografiebuch 7",
      ISBN: 1234567890,
      Fach: "Geografie",
      Kommentar: "Das Geografiebuch für die Klasse 7",
      Originalpreis: 15,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 7,
    },
    7: {
      id: 143,
      Titel: "Geografie-Atlas 7",
      ISBN: 1234567890,
      Fach: "Geografie",
      Kommentar: "Der Geografie-Atlas für die Klasse 7",
      Originalpreis: 40,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 7,
    },
  },

  8: {
    0: {
      id: 153,
      Titel: "Bundle 8",
      ISBN: 0,
      Fach: "Extra",
      Originalpreis: null,
      Kommentar: null,
      Verlag: null,
      Is_Bundle: 1,
      Klasse: 8,
    },
    1: {
      id: 1,
      Titel: "Deutschbuch 8",
      ISBN: 1234567890,
      Fach: "Deutsch",
      Originalpreis: 20,
      Kommentar: "Das Deutschbuch für die Klasse 8",
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 8,
    },
    2: {
      id: 59,
      Titel: "Englischbuch 8",
      ISBN: 1234567890,
      Fach: "Englisch",
      Originalpreis: 12,
      Kommentar: "Das Englischbuch für die Klasse 8",
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 8,
    },
    3: {
      id: 139,
      Titel: "Französischbuch 8",
      ISBN: 1234567890,
      Fach: "Französisch",
      Kommentar: "Das Französischbuch für die Klasse 8",
      Originalpreis: 25,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 8,
    },
    4: {
      id: 121,
      Titel: "Mathematikbuch 8",
      ISBN: 1234567890,
      Fach: "Mathematik",
      Kommentar: "Das Mathematikbuch für die Klasse 8",
      Originalpreis: 18,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 8,
    },
    5: {
      id: 154,
      Titel: "Geschichtsbuch 8",
      ISBN: 1234567890,
      Fach: "Geschichte",
      Kommentar: "Das Geschichtsbuch für die Klasse 8",
      Originalpreis: 30,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 8,
    },
    6: {
      id: 119,
      Titel: "Geografiebuch 8",
      ISBN: 1234567890,
      Fach: "Geografie",
      Kommentar: "Das Geografiebuch für die Klasse 8",
      Originalpreis: 15,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 8,
    },
    7: {
      id: 143,
      Titel: "Geografie-Atlas 8",
      ISBN: 1234567890,
      Fach: "Geografie",
      Kommentar: "Der Geografie-Atlas für die Klasse 8",
      Originalpreis: 40,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 8,
    },
  },

  9: {
    0: {
      id: 153,
      Titel: "Bundle 9",
      ISBN: 0,
      Fach: "Extra",
      Originalpreis: null,
      Kommentar: null,
      Verlag: null,
      Is_Bundle: 1,
      Klasse: 9,
    },
    1: {
      id: 1,
      Titel: "Deutschbuch 9",
      ISBN: 1234567890,
      Fach: "Deutsch",
      Originalpreis: 20,
      Kommentar: "Das Deutschbuch für die Klasse 9",
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 9,
    },
    2: {
      id: 59,
      Titel: "Englischbuch 9",
      ISBN: 1234567890,
      Fach: "Englisch",
      Originalpreis: 12,
      Kommentar: "Das Englischbuch für die Klasse 9",
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 9,
    },
    3: {
      id: 139,
      Titel: "Französischbuch 9",
      ISBN: 1234567890,
      Fach: "Französisch",
      Kommentar: "Das Französischbuch für die Klasse 9",
      Originalpreis: 25,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 9,
    },
    4: {
      id: 121,
      Titel: "Mathematikbuch 9",
      ISBN: 1234567890,
      Fach: "Mathematik",
      Kommentar: "Das Mathematikbuch für die Klasse 9",
      Originalpreis: 18,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 9,
    },
    5: {
      id: 154,
      Titel: "Geschichtsbuch 9",
      ISBN: 1234567890,
      Fach: "Geschichte",
      Kommentar: "Das Geschichtsbuch für die Klasse 9",
      Originalpreis: 30,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 9,
    },
    6: {
      id: 119,
      Titel: "Geografiebuch 9",
      ISBN: 1234567890,
      Fach: "Geografie",
      Kommentar: "Das Geografiebuch für die Klasse 9",
      Originalpreis: 15,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 9,
    },
    7: {
      id: 143,
      Titel: "Geografie-Atlas 9",
      ISBN: 1234567890,
      Fach: "Geografie",
      Kommentar: "Der Geografie-Atlas für die Klasse 9",
      Originalpreis: 40,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 9,
    },
  },

  10: {
    0: {
      id: 153,
      Titel: "Bundle 10",
      ISBN: 0,
      Fach: "Extra",
      Originalpreis: null,
      Kommentar: null,
      Verlag: null,
      Is_Bundle: 1,
      Klasse: 10,
    },
    1: {
      id: 1,
      Titel: "Deutschbuch 10",
      ISBN: 1234567890,
      Fach: "Deutsch",
      Originalpreis: 20,
      Kommentar: "Das Deutschbuch für die Klasse 10",
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 10,
    },
    2: {
      id: 59,
      Titel: "Englischbuch 10",
      ISBN: 1234567890,
      Fach: "Englisch",
      Originalpreis: 12,
      Kommentar: "Das Englischbuch für die Klasse 10",
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 10,
    },
    3: {
      id: 139,
      Titel: "Französischbuch 10",
      ISBN: 1234567890,
      Fach: "Französisch",
      Kommentar: "Das Französischbuch für die Klasse 10",
      Originalpreis: 25,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 10,
    },
    4: {
      id: 121,
      Titel: "Mathematikbuch 10",
      ISBN: 1234567890,
      Fach: "Mathematik",
      Kommentar: "Das Mathematikbuch für die Klasse 10",
      Originalpreis: 18,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 10,
    },
    5: {
      id: 154,
      Titel: "Geschichtsbuch 10",
      ISBN: 1234567890,
      Fach: "Geschichte",
      Kommentar: "Das Geschichtsbuch für die Klasse 10",
      Originalpreis: 30,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 10,
    },
    6: {
      id: 119,
      Titel: "Geografiebuch 10",
      ISBN: 1234567890,
      Fach: "Geografie",
      Kommentar: "Das Geografiebuch für die Klasse 10",
      Originalpreis: 15,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 10,
    },
    7: {
      id: 143,
      Titel: "Geografie-Atlas 10",
      ISBN: 1234567890,
      Fach: "Geografie",
      Kommentar: "Der Geografie-Atlas für die Klasse 10",
      Originalpreis: 40,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 10,
    },
  },

  11: {
    0: {
      id: 153,
      Titel: "Bundle 11",
      ISBN: 0,
      Fach: "Extra",
      Originalpreis: null,
      Kommentar: null,
      Verlag: null,
      Is_Bundle: 1,
      Klasse: 11,
    },
    1: {
      id: 1,
      Titel: "Deutschbuch 11",
      ISBN: 1234567890,
      Fach: "Deutsch",
      Originalpreis: 20,
      Kommentar: "Das Deutschbuch für die Klasse 11",
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 11,
    },
    2: {
      id: 59,
      Titel: "Englischbuch 11",
      ISBN: 1234567890,
      Fach: "Englisch",
      Originalpreis: 12,
      Kommentar: "Das Englischbuch für die Klasse 11",
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 11,
    },
    3: {
      id: 139,
      Titel: "Französischbuch 11",
      ISBN: 1234567890,
      Fach: "Französisch",
      Kommentar: "Das Französischbuch für die Klasse 11",
      Originalpreis: 25,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 11,
    },
    4: {
      id: 121,
      Titel: "Mathematikbuch 11",
      ISBN: 1234567890,
      Fach: "Mathematik",
      Kommentar: "Das Mathematikbuch für die Klasse 11",
      Originalpreis: 18,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 11,
    },
    5: {
      id: 154,
      Titel: "Geschichtsbuch 11",
      ISBN: 1234567890,
      Fach: "Geschichte",
      Kommentar: "Das Geschichtsbuch für die Klasse 11",
      Originalpreis: 30,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 11,
    },
    6: {
      id: 119,
      Titel: "Geografiebuch 11",
      ISBN: 1234567890,
      Fach: "Geografie",
      Kommentar: "Das Geografiebuch für die Klasse 11",
      Originalpreis: 15,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 11,
    },
    7: {
      id: 143,
      Titel: "Geografie-Atlas 11",
      ISBN: 1234567890,
      Fach: "Geografie",
      Kommentar: "Der Geografie-Atlas für die Klasse 11",
      Originalpreis: 40,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 11,
    },
  },

  12: {
    0: {
      id: 153,
      Titel: "Bundle 12",
      ISBN: 0,
      Fach: "Extra",
      Originalpreis: null,
      Kommentar: null,
      Verlag: null,
      Is_Bundle: 1,
      Klasse: 12,
    },
    1: {
      id: 1,
      Titel: "Deutschbuch 12",
      ISBN: 1234567890,
      Fach: "Deutsch",
      Originalpreis: 20,
      Kommentar: "Das Deutschbuch für die Klasse 12",
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 12,
    },
    2: {
      id: 59,
      Titel: "Englischbuch 12",
      ISBN: 1234567890,
      Fach: "Englisch",
      Originalpreis: 12,
      Kommentar: "Das Englischbuch für die Klasse 12",
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 12,
    },
    3: {
      id: 139,
      Titel: "Französischbuch 12",
      ISBN: 1234567890,
      Fach: "Französisch",
      Kommentar: "Das Französischbuch für die Klasse 12",
      Originalpreis: 25,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 12,
    },
    4: {
      id: 121,
      Titel: "Mathematikbuch 12",
      ISBN: 1234567890,
      Fach: "Mathematik",
      Kommentar: "Das Mathematikbuch für die Klasse 12",
      Originalpreis: 18,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 12,
    },
    5: {
      id: 154,
      Titel: "Geschichtsbuch 12",
      ISBN: 1234567890,
      Fach: "Geschichte",
      Kommentar: "Das Geschichtsbuch für die Klasse 12",
      Originalpreis: 30,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 12,
    },
    6: {
      id: 119,
      Titel: "Geografiebuch 12",
      ISBN: 1234567890,
      Fach: "Geografie",
      Kommentar: "Das Geografiebuch für die Klasse 12",
      Originalpreis: 15,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 12,
    },
    7: {
      id: 143,
      Titel: "Geografie-Atlas 12",
      ISBN: 1234567890,
      Fach: "Geografie",
      Kommentar: "Der Geografie-Atlas für die Klasse 12",
      Originalpreis: 40,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 12,
    },
  },

  13: {
    0: {
      id: 153,
      Titel: "Bundle 13",
      ISBN: 0,
      Fach: "Extra",
      Originalpreis: null,
      Kommentar: null,
      Verlag: null,
      Is_Bundle: 1,
      Klasse: 13,
    },
    1: {
      id: 1,
      Titel: "Deutschbuch 13",
      ISBN: 1234567890,
      Fach: "Deutsch",
      Originalpreis: 20,
      Kommentar: "Das Deutschbuch für die Klasse 13",
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 13,
    },
    2: {
      id: 59,
      Titel: "Englischbuch 13",
      ISBN: 1234567890,
      Fach: "Englisch",
      Originalpreis: 12,
      Kommentar: "Das Englischbuch für die Klasse 13",
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 13,
    },
    3: {
      id: 139,
      Titel: "Französischbuch 13",
      ISBN: 1234567890,
      Fach: "Französisch",
      Kommentar: "Das Französischbuch für die Klasse 13",
      Originalpreis: 25,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 13,
    },
    4: {
      id: 121,
      Titel: "Mathematikbuch 13",
      ISBN: 1234567890,
      Fach: "Mathematik",
      Kommentar: "Das Mathematikbuch für die Klasse 13",
      Originalpreis: 18,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 13,
    },
    5: {
      id: 154,
      Titel: "Geschichtsbuch 13",
      ISBN: 1234567890,
      Fach: "Geschichte",
      Kommentar: "Das Geschichtsbuch für die Klasse 13",
      Originalpreis: 30,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 13,
    },
    6: {
      id: 119,
      Titel: "Geografiebuch 13",
      ISBN: 1234567890,
      Fach: "Geografie",
      Kommentar: "Das Geografiebuch für die Klasse 13",
      Originalpreis: 15,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 13,
    },
    7: {
      id: 143,
      Titel: "Geografie-Atlas 13",
      ISBN: 1234567890,
      Fach: "Geografie",
      Kommentar: "Der Geografie-Atlas für die Klasse 13",
      Originalpreis: 40,
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 13,
    },
  },

  0: {
    0: {
      id: 1,
      Titel: "Formelsammlung",
      ISBN: 1234567890,
      Fach: "Extra",
      Originalpreis: 20,
      Kommentar: "Die Formelsammlung für naturwissenschaftliche Fächer",
      Verlag: "Bookay - Verlag",
      Is_Bundle: 0,
      Klasse: 0,
    },
  },
}

const activePositions = {
  0: {
    LID: 3,
    SID: 7,
    BID: 7,
    CID: 0,
    Aktion: 0,
    Status: "suche",
    PartnerLID: null,
    Datum: "2023-03-21 21:18:08",
    SchulID: 1,
    BlockedLIDs: null,
    VerhandelterPreis: null,
    Titel: "Deutschbuch 7",
    ISBN: 1234567890,
    Verlag: "Bookay - Verlag",
    Fach: 1,
    Klasse: 7,
    Originalpreis: 20,
    Aktiv: 1,
    Is_Bundle: 0,
    Kommentar: "Das Deutschbuch für die Klasse 7",
    FID: 1,
    Name: "Deutsch",
    Art: null,
    HatBild: 1,
    BildURL: null,
    HinzugefuegtVon: 0,
    Bestaetigt: 1,
  },
  1: {
    LID: 3,
    SID: 7,
    BID: 8,
    CID: 0,
    Aktion: 0,
    Status: "suche",
    PartnerLID: null,
    Datum: "2023-03-21 21:18:08",
    SchulID: 1,
    BlockedLIDs: null,
    VerhandelterPreis: null,
    Titel: "Englischbuch 7",
    ISBN: 1234567890,
    Verlag: "Bookay - Verlag",
    Fach: 2,
    Klasse: 7,
    Originalpreis: 12,
    Aktiv: 1,
    Is_Bundle: 0,
    Kommentar: "Das Englischbuch für die Klasse 7",
    FID: 2,
    Name: "Englisch",
    Art: null,
    HatBild: 1,
    BildURL: null,
    HinzugefuegtVon: 0,
    Bestaetigt: 1,
  },
  2: {
    LID: 3,
    SID: 7,
    BID: 9,
    CID: 1,
    Aktion: 0,
    Status: "matched",
    PartnerLID: 1,
    Datum: "2023-03-21 21:18:08",
    SchulID: 1,
    BlockedLIDs: null,
    VerhandelterPreis: null,
    Titel: "Mathematikbuch 7",
    ISBN: 1234567890,
    Verlag: "Bookay - Verlag",
    Fach: 3,
    Klasse: 7,
    Originalpreis: 18,
    Aktiv: 1,
    Is_Bundle: 0,
    Kommentar: "Das Mathematikbuch für die Klasse 7",
    FID: 3,
    Name: "Mathematik",
    Art: null,
    HatBild: 1,
    BildURL: null,
    HinzugefuegtVon: 0,
    Bestaetigt: 1,
  },
  3: {
    LID: 3,
    SID: 7,
    BID: 10,
    CID: 2,
    Aktion: 0,
    Status: "matched",
    PartnerLID: null,
    Datum: "2023-03-21 21:18:08",
    SchulID: 1,
    BlockedLIDs: null,
    VerhandelterPreis: null,
    Titel: "Geschichtsbuch 7",
    ISBN: 1234567890,
    Verlag: "Bookay - Verlag",
    Fach: 1,
    Klasse: 7,
    Originalpreis: 30,
    Aktiv: 1,
    Is_Bundle: 0,
    Kommentar: "Das Geschichtsbuch für die Klasse 7",
    FID: 1,
    Name: "Geschichte",
    Art: null,
    HatBild: 1,
    BildURL: null,
    HinzugefuegtVon: 0,
    Bestaetigt: 1,
  },
  4: {
    LID: 5,
    SID: 7,
    BID: 11,
    CID: 0,
    Aktion: 1,
    Status: "suche",
    PartnerLID: null,
    Datum: "2023-03-21 21:18:08",
    SchulID: 1,
    BlockedLIDs: null,
    VerhandelterPreis: null,
    Titel: "Deutschbuch 8",
    ISBN: 1234567890,
    Verlag: "Bookay - Verlag",
    Fach: 1,
    Klasse: 8,
    Originalpreis: 20,
    Aktiv: 1,
    Is_Bundle: 0,
    Kommentar: "Das Deutschbuch für die Klasse 8",
    FID: 1,
    Name: "Deutsch",
    Art: null,
    HatBild: 1,
    BildURL: null,
    HinzugefuegtVon: 0,
    Bestaetigt: 1,
  },
  5: {
    LID: 3,
    SID: 7,
    BID: 12,
    CID: 0,
    Aktion: 1,
    Status: "suche",
    PartnerLID: null,
    Datum: "2023-03-21 21:18:08",
    SchulID: 1,
    BlockedLIDs: null,
    VerhandelterPreis: null,
    Titel: "Englischbuch 8",
    ISBN: 1234567890,
    Verlag: "Bookay - Verlag",
    Fach: 2,
    Klasse: 8,
    Originalpreis: 12,
    Aktiv: 1,
    Is_Bundle: 0,
    Kommentar: "Das Englischbuch für die Klasse 8",
    FID: 2,
    Name: "Englisch",
    Art: null,
    HatBild: 1,
    BildURL: null,
    HinzugefuegtVon: 0,
    Bestaetigt: 1,
  },
  6: {
    LID: 3,
    SID: 7,
    BID: 13,
    CID: 0,
    Aktion: 1,
    Status: "matched",
    PartnerLID: null,
    Datum: "2023-03-21 21:18:08",
    SchulID: 1,
    BlockedLIDs: null,
    VerhandelterPreis: null,
    Titel: "Geografie-Atlas 8",
    ISBN: 1234567890,
    Verlag: "Bookay - Verlag",
    Fach: 2,
    Klasse: 8,
    Originalpreis: 40,
    Aktiv: 1,
    Is_Bundle: 0,
    Kommentar: "Der Geografie-Atlas für die Klasse 8",
    FID: 2,
    Name: "Geografie",
    Art: null,
    HatBild: 1,
    BildURL: null,
    HinzugefuegtVon: 0,
    Bestaetigt: 1,
  },
}

const historyPositions = {
  0: {
    LID: 8,
    SID: 7,
    BID: 153,
    CID: 3,
    Aktion: 0,
    Status: "fertig",
    PartnerLID: 7,
    Datum: "2023-03-28 17:55:54",
    SchulID: 1,
    BlockedLIDs: null,
    VerhandelterPreis: 12,
    Titel: "Französischbuch 7",
    ISBN: 1234567890,
    Verlag: "Bookay - Verlag",
    Fach: 0,
    Klasse: 7,
    Originalpreis: 25,
    Aktiv: 1,
    Is_Bundle: 0,
    Kommentar: "Das Französischbuch für die Klasse 7",
    FID: 0,
    Name: "Französisch",
    Art: null,
    HatBild: 0,
    BildURL: null,
    HinzugefuegtVon: 0,
    Bestaetigt: 1,
  },
  1: {
    LID: 8,
    SID: 7,
    BID: 153,
    CID: 3,
    Aktion: 1,
    Status: "fertig",
    PartnerLID: 7,
    Datum: "2023-03-28 17:55:54",
    SchulID: 1,
    BlockedLIDs: null,
    VerhandelterPreis: 8,
    Titel: "Geografiebuch 8",
    ISBN: 1234567890,
    Verlag: "Bookay - Verlag",
    Fach: 0,
    Klasse: 8,
    Originalpreis: 15,
    Aktiv: 1,
    Is_Bundle: 0,
    Kommentar: "Das Geografiebuch für die Klasse 8",
    FID: 0,
    Name: "Geografie",
    Art: null,
    HatBild: 0,
    BildURL: null,
    HinzugefuegtVon: 0,
    Bestaetigt: 1,
  },
}

export const getBooksByClass = (classNumber, setAll) => {
  const booksClass = books[classNumber]
  const formatted = booksClass
  let result = Object.keys(formatted).map((key) => formatted[key])
  setAll(result)
}

export const getActivePositions = (setAll) => {
  const formatted = activePositions
  let result = Object.keys(formatted).map((key) => formatted[key])
  setAll(result)
}

export const getHistoryPositions = (setAll) => {
  const formatted = historyPositions
  let result = Object.keys(formatted).map((key) => formatted[key])
  setAll(result)
}
