const chats = {
  0: {
    FID: 8,
    Name: "Französisch",
    Art: null,
    HatBild: 0,
    BildURL: null,
    HinzugefuegtVon: 0,
    Bestaetigt: 1,
    CID: 6,
    Verlauf:
      "{\u0022chat\u0022: [{\u0022msg\u0022: \u0022Hi, ich würde gerne das Französisch-Buch kaufen. \u0022, \u0022sender\u0022: \u00221\u0022, \u0022datetime\u0022: \u002205.04.2023-21:07\u0022}, {\u0022msg\u0022: \u0022Hallo, das Buch kann ich dir morgen in der großen Pause übergeben. Lass uns vor dem Raum A567 treffen.\u0022, \u0022sender\u0022: \u00220\u0022, \u0022datetime\u0022: \u002205.04.2023-21:08\u0022}, {\u0022msg\u0022: \u0022Es würde dann 10 € kosten\u0022, \u0022sender\u0022: \u00220\u0022, \u0022datetime\u0022: \u002205.04.2023-21:09\u0022}, {\u0022msg\u0022: \u0022Klar! Dann bis morgen\u0022, \u0022sender\u0022: \u00221\u0022, \u0022datetime\u0022: \u002205.04.2023-21:10\u0022}]}",
    LID: 8,
    SID: 7,
    BID: 153,
    Aktion: 0,
    Status: "fertig",
    PartnerLID: 7,
    Datum: "2023-03-28 17:55:54",
    SchulID: 1,
    BlockedLIDs: null,
    VerhandelterPreis: 10,
    Titel: "Französischbuch 7",
    ISBN: 1234567890,
    Verlag: "Bookay - Verlag",
    Fach: 8,
    Klasse: 7,
    Originalpreis: 25,
    Aktiv: 0,
    Is_Bundle: 0,
    Kommentar: "Das Französischbuch für die Klasse 7",
  },
  1: {
    FID: 8,
    Name: "Geografie",
    Art: null,
    HatBild: 0,
    BildURL: null,
    HinzugefuegtVon: 0,
    Bestaetigt: 1,
    CID: 9,
    Verlauf:
      "{\u0022chat\u0022: [{\u0022msg\u0022: \u0022Hey, ich würde dir gerne das Geografie-Buch abkaufen. \u0022, \u0022sender\u0022: \u00221\u0022, \u0022datetime\u0022: \u002205.04.2023-21:07\u0022}, {\u0022msg\u0022: \u0022Das Buch kann ich dir für 8 € abgeben.\u0022, \u0022sender\u0022: \u00220\u0022, \u0022datetime\u0022: \u002205.04.2023-21:08\u0022}, {\u0022msg\u0022: \u0022Ist das in Ordnung für dich?\u0022, \u0022sender\u0022: \u00220\u0022, \u0022datetime\u0022: \u002205.04.2023-21:09\u0022}, {\u0022msg\u0022: \u0022Ja, das ist gut. Wann bist Du in der Schule, damit ich das Buch abholen kann?\u0022, \u0022sender\u0022: \u00221\u0022, \u0022datetime\u0022: \u002205.04.2023-21:10\u0022}, {\u0022msg\u0022: \u0022Ich bin morgen in der kleinen Pause im Raum A234, wie wäre es damit?\u0022, \u0022sender\u0022: \u00220\u0022, \u0022datetime\u0022: \u002205.04.2023-21:11\u0022}, {\u0022msg\u0022: \u0022Okay, ich komme dann morgen vorbei! Danke!\u0022, \u0022sender\u0022: \u00221\u0022, \u0022datetime\u0022: \u002205.04.2023-21:12\u0022}]}",
    LID: 8,
    SID: 7,
    BID: 153,
    Aktion: 1,
    Status: "fertig",
    PartnerLID: 7,
    Datum: "2023-03-28 17:55:54",
    SchulID: 1,
    BlockedLIDs: null,
    VerhandelterPreis: 8,
    Titel: "Geografiebuch 8",
    ISBN: 1234567890,
    Verlag: "Bookay - Verlag",
    Fach: 8,
    Klasse: 8,
    Originalpreis: 15,
    Aktiv: 0,
    Is_Bundle: 0,
    Kommentar: "Das Geografiebuch für die Klasse 8",
  },
  3: {
    FID: 0,
    Name: "Geografie",
    Art: null,
    HatBild: 0,
    BildURL: null,
    HinzugefuegtVon: 0,
    Bestaetigt: 1,
    CID: 3,
    Verlauf:
      "{\u0022chat\u0022: [{\u0022msg\u0022: \u0022Hallo! Für wie viel würdest Du das Buch loswerden wollen? \u0022, \u0022sender\u0022: \u00221\u0022, \u0022datetime\u0022: \u002205.04.2023-21:07\u0022}, {\u0022msg\u0022: \u0022Hi, für die Hälfte würde ich es gerne abgeben.\u0022, \u0022sender\u0022: \u00220\u0022, \u0022datetime\u0022: \u002205.04.2023-21:08\u0022}, {\u0022msg\u0022: \u0022Das wären dann 20 €\u0022, \u0022sender\u0022: \u00220\u0022, \u0022datetime\u0022: \u002205.04.2023-21:09\u0022}, {\u0022msg\u0022: \u0022Einverstanden! Wollen wir uns dann morgen in der ersten Pause vor dem Haupteingang treffen?\u0022, \u0022sender\u0022: \u00221\u0022, \u0022datetime\u0022: \u002205.04.2023-21:10\u0022}, {\u0022msg\u0022: \u0022Klar, dann bis morgen!\u0022, \u0022sender\u0022: \u00220\u0022, \u0022datetime\u0022: \u002205.04.2023-21:11\u0022}]}",
    LID: 8,
    SID: 7,
    BID: 153,
    Aktion: 1,
    Status: "matched",
    PartnerLID: 7,
    Datum: "2023-03-28 17:55:54",
    SchulID: 1,
    BlockedLIDs: null,
    VerhandelterPreis: null,
    Titel: "Geografie-Atlas 8",
    ISBN: 1234567890,
    Verlag: "Bookay - Verlag",
    Fach: 8,
    Klasse: 8,
    Originalpreis: 40,
    Aktiv: 1,
    Is_Bundle: 0,
    Kommentar: "Der Geografie-Atlas für die Klasse 8",
  },
  6: {
    FID: 8,
    Name: "Mathematik",
    Art: null,
    HatBild: 0,
    BildURL: null,
    HinzugefuegtVon: 0,
    Bestaetigt: 1,
    CID: 5,
    Verlauf:
      "{\u0022chat\u0022: [{\u0022msg\u0022: \u0022Hi, ich würde gerne dieses Buch kaufen. \u0022, \u0022sender\u0022: \u00221\u0022, \u0022datetime\u0022: \u002205.04.2023-21:07\u0022}, {\u0022msg\u0022: \u0022Hallo, du kannst es für 10 € haben. Ich bin morgen nur nach der 4. Stunde da, also lass uns vorher treffen.\u0022, \u0022sender\u0022: \u00220\u0022, \u0022datetime\u0022: \u002205.04.2023-21:08\u0022}, {\u0022msg\u0022: \u0022Ich bin da im Raum A123\u0022, \u0022sender\u0022: \u00220\u0022, \u0022datetime\u0022: \u002205.04.2023-21:09\u0022}, {\u0022msg\u0022: \u0022Okay!\u0022, \u0022sender\u0022: \u00221\u0022, \u0022datetime\u0022: \u002205.04.2023-21:10\u0022}]}",
    LID: 8,
    SID: 7,
    BID: 153,
    Aktion: 0,
    Status: "matched",
    PartnerLID: 7,
    Datum: "2023-03-28 17:55:54",
    SchulID: 1,
    BlockedLIDs: null,
    VerhandelterPreis: null,
    Titel: "Mathematikbuch 7",
    ISBN: 1234567890,
    Verlag: "Bookay - Verlag",
    Fach: 8,
    Klasse: 7,
    Originalpreis: 18,
    Aktiv: 1,
    Is_Bundle: 0,
    Kommentar: "Das Mathematikbuch für die Klasse 7",
  },
  2: {
    FID: 8,
    Name: "Geschichte",
    Art: null,
    HatBild: 0,
    BildURL: null,
    HinzugefuegtVon: 0,
    Bestaetigt: 1,
    CID: 4,
    Verlauf:
      "{\u0022chat\u0022: [{\u0022msg\u0022: \u0022Hi, ich würde gerne das Geschichte-Buch kaufen. \u0022, \u0022sender\u0022: \u00221\u0022, \u0022datetime\u0022: \u002205.04.2023-21:07\u0022}, {\u0022msg\u0022: \u0022Hallo, lass uns am Freitag vor der 1. Stunde in Raum A456 treffen.\u0022, \u0022sender\u0022: \u00220\u0022, \u0022datetime\u0022: \u002205.04.2023-21:08\u0022}, {\u0022msg\u0022: \u0022Das Buch würde ich für 16 € abgeben\u0022, \u0022sender\u0022: \u00220\u0022, \u0022datetime\u0022: \u002205.04.2023-21:09\u0022}, {\u0022msg\u0022: \u0022Klar!\u0022, \u0022sender\u0022: \u00221\u0022, \u0022datetime\u0022: \u002205.04.2023-21:10\u0022}]}",
    LID: 8,
    SID: 7,
    BID: 153,
    Aktion: 0,
    Status: "matched",
    PartnerLID: 7,
    Datum: "2023-03-28 17:55:54",
    SchulID: 1,
    BlockedLIDs: null,
    VerhandelterPreis: null,
    Titel: "Geschichtsbuch 7",
    ISBN: 1234567890,
    Verlag: "Bookay - Verlag",
    Fach: 8,
    Klasse: 7,
    Originalpreis: 30,
    Aktiv: 1,
    Is_Bundle: 0,
    Kommentar: "Das Geschichtsbuch für die Klasse 7",
  },
}

export const getAllChats = (setAll) => {
  const formatted = chats
  let result = Object.keys(formatted).map((key) => formatted[key])
  setAll(result)
}
