import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Box, Typography, MenuItem, FormControl, ListItemText, Menu, Button, Grid } from "@mui/material"
import SchoolTwoToneIcon from "@mui/icons-material/SchoolTwoTone"
import { F_INDEX } from "../constants/routes"

const UserMenu = ({ bookmanagement }) => {
  const navigate = useNavigate()

  const [openMenu, setOpenMenu] = useState(null)
  const open = Boolean(openMenu)

  const handleClose = () => {
    setOpenMenu(null)
  }
  const handleOpen = (event) => {
    setOpenMenu(event.currentTarget)
  }

  return (
    <Box sx={{ fontSize: "inherit", width: "80%", mx: "auto" }}>
      <Button
        variant="contained"
        fullWidth
        onClick={handleOpen}
        sx={{
          mt: "1vh",
          boxShadow: "none",
          height: "15vh",
          backgroundColor: bookmanagement ? "bmprimary.main" : "initial",
          "&:hover": {
            backgroundColor: bookmanagement ? "bmsecondary.main" : "initial",
          },
        }}>
        <Grid container>
          <Grid item xs={12}>
            <SchoolTwoToneIcon sx={{ color: "white", mx: "auto" }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ color: "white", textTransform: "none" }}>
              Beispiel-Schule-zur-Demonstration
            </Typography>
          </Grid>
        </Grid>
      </Button>

      <FormControl sx={{ visibility: "none" }}>
        <Menu
          id="user-menu"
          open={open}
          onClose={handleClose}
          anchorEl={openMenu}
          keepMounted
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}>
          <MenuItem value="1" key="1">
            <ListItemText onClick={() => navigate(F_INDEX)}>Übersicht</ListItemText>
          </MenuItem>
          <MenuItem value="2" key="2">
            <ListItemText>
              <a href="https://www.bookay-buecher.de/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "initial" }}>
                bookay-buecher.de
              </a>
            </ListItemText>
          </MenuItem>
        </Menu>
      </FormControl>
    </Box>
  )
}
export default UserMenu
